import { AnalyticsBrowser } from '@segment/analytics-next';
import ENV from 'env';
import { User, Venue } from 'models';

import { store } from 'store';
import { createPage } from 'utils/tracking/createPage';
import { createTrack } from './createTrack';
import { isTrackingServiceUp, isValidVenueId } from './trackingService.utils';

const init = (): AnalyticsBrowser | undefined => {
  return ENV.UALA_SEGMENT_KEY ? AnalyticsBrowser.load({ writeKey: ENV.UALA_SEGMENT_KEY }) : undefined;
};

const serviceSingleton = init();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window['__SEGMENT__ANALYTICS__'] = serviceSingleton;

const group = (venue: Venue, country: string): void => {
  if (isTrackingServiceUp(serviceSingleton) && isValidVenueId(venue.id)) {
    serviceSingleton.group(venue.id.toString(), {
      name: venue.name,
      locale: venue.locale,
      postalCode: venue.postal_code,
      createdAt: venue.created_at,
      city: venue.town,
      companyName: venue.company_name,
      country,
      plan: venue.plan,
      activeWarehouse: venue.active_warehouse,
      activeCashRegister: venue.active_cash_register,
      activeCustomerPrivacyManagement: venue.active_customer_privacy_management,
      activeAgenda: venue.active_agenda,
      activeMarketing: venue.active_marketing,
      activeExpenses: venue.active_expenses,
      activeManagerStatistics: venue.active_manager_statistics,
      activeGallery: venue.active_gallery,
      activeCustomerSupport: venue.active_customer_support,
      activeBasicPromotions: venue.active_basic_promotions,
      activeAdvancedPromotions: venue.active_advanced_promotions,
      activeStatisticsTrend: venue.active_statistics_trend,
      activeStatisticsCompanyReport: venue.active_statistics_company_report,
      activeStatisticsCollaboratorsReport: venue.active_statistics_collaborators_report,
      activeStatisticsCustomerReport: venue.active_statistics_customer_report,
      activeStatisticsStockReport: venue.active_statistics_stock_report,
      activeStatisticsInventoryReport: venue.active_statistics_inventory_report,
      usingOldBillingPlanLogic: venue.using_old_billing_plan_logic,
      activeListing: venue.active_listing,
      activeMarketplacePage: venue.active_marketplace_page,
      activeVpos: venue.active_vpos,
      activeEcommerce: venue.active_ecommerce,
      activeSmsCustomSender: venue.active_sms_custom_sender,
      featureRoomStaffMember: venue.feature_room_staff_member,
      featureMultiBooking: venue.feature_multi_booking,
      activePromotions: venue.active_promotions,
      trackingVersion: 'v2',
    });
  }
};

const identify = (user: User, country: string, venueIds: ReadonlyArray<Venue['id']>): void => {
  if (isTrackingServiceUp(serviceSingleton)) {
    serviceSingleton.identify(user.id.toString(), {
      email: user.email,
      venueId: user.venue_id,
      country,
      associatedVenueIds: venueIds,
      trackingVersion: 'v2',
    });
  }
};

const analyticsContext = {
  getAnalyticsBrowser: () => serviceSingleton,
  getStore: () => store,
  getWindowData: () => ({ ...window.location, referrer: document.referrer, title: document.title }),
};
const track = createTrack(analyticsContext);
const page = createPage(analyticsContext);

export { group, identify, init, page, track };
