import {
  AcceptanceForm,
  AssociatedCustomer,
  Checkout,
  MarketingPromotion,
  ReachedLimit,
  Reservation,
  Supplier,
} from 'models';
import { ErrorResponse } from 'actions/data_providers/api';
import { ReactElement } from 'react';
import { OnCheckoutSubmit } from './ecommerce';
import { modalCloseAction, modalOpenAction } from './modals.actions';
import { ParametricActionCreator, ParametricDispatch } from './types';
import { FiscalPrinterDocument } from 'utils/fiscal-printer';
import { UnknownFunction } from 'utils/utilityTypes';

type ModalsComponentList =
  | 'Alert'
  | 'Banner'
  | 'Confirm'
  | 'Attention'
  | 'Success'
  | 'Info'
  | 'ServerError'
  | 'CMS'
  | 'fields/Date'
  | 'VenueAcceptanceForm'
  | 'CustomerInfo'
  | 'CustomerHistory'
  | 'customers/CustomerConflict'
  | 'reservation_next/ReservationWindow'
  | 'checkout/RevertCheckout'
  | 'checkout/RCHDocumentCancelation'
  | 'checkout/CashBalance'
  | 'checkout/FiscalDay'
  | 'checkout/FiscalDayPrint'
  | 'checkout/ReprintCheckout'
  | 'checkout/UpdateNativeWrappers'
  | 'checkout/CheckoutConflictErrorModal'
  | 'promotions/AssociatedCustomersModal'
  | 'promotions/MarketingPromotionRedeem'
  | 'promotions/CustomerPromotionCheckout'
  | 'promotions/ArchivePromotion'
  | 'reachedLimits/ReachedLimitsBookings'
  | 'payment_methods/AddPaymentMethod'
  | 'ContactUs'
  | 'detailed_monitoring/DetailedMonitoring'
  | 'printers/ReceiptVoid'
  | 'ecommerce/EcommerceOrderCheckout'
  | 'promo_flash/PromoFlashEdit'
  | 'warehouse/NewSupplier'
  | 'manager/company/analyses/AppointmentsDetails'
  | 'manager_next/IncomeStatementSettings'
  | 'manager_next/ServicesSettings'
  | 'manager/collaborators/analyses/ModalStaffMemberVacancies'
  | 'users/UpsertUserModal'
  | 'ToastMessage'
  | 'PendingStatus'
  | 'users/DeleteUserModal'
  | 'DailyAppointments'
  | 'PendingToProtect'
  | 'appointments/PaymentProtectionConfirmBanner'
  | 'appointments/PendingAppointmentsBanner'
  | 'treatments/GenericModalPossiblePackageChange'
  | 'treatments/ConfirmDeleteAllVariants'
  | 'treatments/ConfirmDisablePatchTestingModal'
  | 'version_update/WinDesktopUpdateVersionBanner'
  | 'warehouse/WarningModal'
  | 'warehouse/Toast'
  | 'warehouse/barcode-scanning/MobileBarcodeScanningModal'
  | 'warehouse/barcode-scanning/MobileBarcodeScanningUnloadProductModal'
  | 'treatments/AddVenueTreatment'
  | 'warehouse/venue-product-add-stock/VenueProductAddStockModal'
  | 'warehouse/venue-product-correct-stock/VenueProductCorrectStockModal'
  | 'warehouse/venue-product-unload/VenueProductUnloadModal'
  | 'warehouse/venue-product-unload-history/VenueProductUnloadHistoryModal'
  | 'warehouse/add-new-product/AddNewProductModal'
  | 'warehouse/unload-product/SearchUnloadableProductsModal'
  | 'payments/KYCAlert'
  | 'payments/KYCAlertFlashMessage'
  | 'EditProfileFormModal';

type OnSubmit = (a: OnCheckoutSubmit) => Promise<void>;
type OnEvent<A> = (date?: A) => void;
type OnConfirm<A> = (a: A) => Promise<unknown>;
type OnCreateSupplier = (supplier: Supplier) => void;
type OnSuccess = (resp: { marketing_promotion: MarketingPromotion }) => void;

interface ExtraModalConfig<A> {
  [key: string]:
    | number
    | string
    | Checkout
    | (OnEvent<A> | OnSubmit | OnCreateSupplier | OnSuccess | OnConfirm<A>)
    | undefined
    | null
    | boolean
    | ReachedLimit
    | Partial<ErrorResponse>
    | ReactElement
    | Array<JSX.Element>
    | MarketingPromotion
    | Array<AssociatedCustomer>
    | AcceptanceForm
    | { staff_member_id: number; time: unknown }
    | Reservation
    | FiscalPrinterDocument[]
    | UnknownFunction
    | string[]
    | unknown;
}

export type ModalConfig<A> = ModalConfigV1<A> | ModalConfigV2<A>;
export interface ModalConfigV1<A> extends ExtraModalConfig<A> {
  component: ModalsComponentList;
}
export interface ModalConfigV2<A> extends ExtraModalConfig<A> {
  componentPath: string;
}

export interface ModalParams<A = unknown> {
  id: string;
  config?: ModalConfig<A>;
}

export type ModalProps<C> = {
  config: C;
  closeModal: () => Promise<void>;
};

export type ModalActionCreator = ParametricActionCreator<ModalParams, Promise<void>>;

export type ModalActionDispatch = ParametricDispatch<ModalParams, Promise<void>>;

//TODO [2022-08-07] change to ({ id, config }: Params) => (dispatch: Dispatch<AnyAction>)
export const modalOpen: ModalActionCreator =
  ({ id, config }) =>
  (dispatch): Promise<void> =>
    new Promise((resolve) => {
      dispatch(modalOpenAction({ id, config }));
      resolve(undefined);
    });

export const modalClose: ModalActionCreator =
  ({ id }) =>
  (dispatch): Promise<undefined> =>
    new Promise((resolve) => {
      dispatch(modalCloseAction({ id }));
      resolve(undefined);
    });
